import React, { useState, useRef } from 'react';
import { Headphones, GraduationCap, FileText } from 'lucide-react';
import { hebrewText } from '../lib/hebrew';
import { extractTextFromPDF } from '../lib/pdf';
import { generatePodcast, generateSummary, generateTest } from '../lib/openai';
import { useUserStats } from '../hooks/useUserStats';
import Hero from './Hero';
import HowItWorks from './HowItWorks';
import ProcessingAnimation from './ProcessingAnimation';
import PodcastPlayer from './PodcastPlayer';
import TestQuestions from './TestQuestions';
import SummaryModal from './SummaryModal';
import PricingModal from './PricingModal';
import TokenCounter from './TokenCounter';
import AuthModal from './AuthModal';
import FeatureVoting from './FeatureVoting';
import ActionCard from './ActionCard';
import Testimonials from './Testimonials';
import { useAuth } from '../hooks/useAuth';

interface MainContentProps {
  onTokenUse: () => void;
}

const MainContent: React.FC<MainContentProps> = ({ onTokenUse }) => {
  const { user } = useAuth();
  const { tokenCount, timeToReset, decrementToken } = useUserStats(user?.uid);
  const [file, setFile] = useState<File | null>(null);
  const [subject, setSubject] = useState<string>('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [processingType, setProcessingType] = useState<'podcast' | 'test' | 'summary'>('podcast');
  const [audioUrl, setAudioUrl] = useState<string>('');
  const [questions, setQuestions] = useState<any[]>([]);
  const [summary, setSummary] = useState('');
  const [showPodcast, setShowPodcast] = useState(false);
  const [showTest, setShowTest] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [showPricing, setShowPricing] = useState(false);
  const [showAuth, setShowAuth] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const mascotRef = useRef<HTMLDivElement>(null);
  const [hasGeneratedOnce, setHasGeneratedOnce] = useState(false);
  const [pendingGenerationType, setPendingGenerationType] = useState<'podcast' | 'test' | 'summary' | null>(null);

  const handleFileSelect = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile && selectedFile.type === 'application/pdf') {
      setFile(selectedFile);
      setSubject('');
      setError(null);
    } else {
      setError(hebrewText.errors.pdfOnly);
    }
  };

  const handleDrop = (event: React.DragEvent) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    if (droppedFile && droppedFile.type === 'application/pdf') {
      setFile(droppedFile);
      setSubject('');
      setError(null);
    } else {
      setError(hebrewText.errors.pdfOnly);
    }
  };

  const handleDragOver = (event: React.DragEvent) => {
    event.preventDefault();
  };

  const handleTopicSubmit = async (topic: string) => {
    setSubject(topic);
    setFile(null);
  };

  const handleGenerate = async (type: 'podcast' | 'test' | 'summary') => {
    // For non-authenticated users who have already generated once
    if (!user && hasGeneratedOnce) {
      setPendingGenerationType(type);
      setShowAuth(true);
      return;
    }

    // Check if user has tokens available
    const canGenerate = await decrementToken();
    if (!canGenerate) {
      setShowPricing(true);
      return;
    }

    try {
      setIsProcessing(true);
      setProcessingType(type);
      setError(null);

      let content: string;
      if (file) {
        content = await extractTextFromPDF(file);
      } else if (subject) {
        content = subject;
      } else {
        throw new Error('No content provided');
      }

      switch (type) {
        case 'podcast':
          const audioUrl = await generatePodcast(content);
          setAudioUrl(audioUrl);
          setShowPodcast(true);
          break;
        case 'test':
          const questions = await generateTest(content);
          setQuestions(questions);
          setShowTest(true);
          break;
        case 'summary':
          const summary = await generateSummary(content);
          setSummary(summary);
          setShowSummary(true);
          break;
      }

      // Handle first generation for non-authenticated users
      if (!user) {
        setHasGeneratedOnce(true);
      }

      onTokenUse();
    } catch (error) {
      console.error('Generation error:', error);
      setError(error instanceof Error ? error.message : hebrewText.errors.generation);
    } finally {
      setIsProcessing(false);
    }
  };

  const showGenerationOptions = Boolean(file || subject) && !isProcessing;
  const showInitialContent = !showGenerationOptions && !isProcessing;

  return (
    <main className="max-w-6xl mx-auto px-4 sm:px-6">
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        accept=".pdf"
        className="hidden"
      />

      {error && (
        <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded-lg mb-6" role="alert">
          {error}
        </div>
      )}

      {showInitialContent && (
        <>
          <Hero
            onFileSelect={handleFileSelect}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            mascotRef={mascotRef}
            onTopicSubmit={handleTopicSubmit}
          />
          <HowItWorks onGetStarted={handleFileSelect} />
          <Testimonials />
        </>
      )}

      {showGenerationOptions && (
        <div className="py-8 space-y-12">
          <div className="space-y-6">
            <div className="flex flex-col gap-4">
              <TokenCounter
                tokensLeft={tokenCount}
                timeToReset={timeToReset}
                onUpgradeClick={() => setShowPricing(true)}
              />

              {!user && hasGeneratedOnce && (
                <div className="bg-[#7e41cc]/10 border border-[#7e41cc]/20 rounded-lg p-4">
                  <p className="text-[#1a1825] text-center">
                    כדי להמשיך ליצור חומרי לימוד, אנא התחבר או הירשם לחשבון חדש.
                  </p>
                </div>
              )}

              {subject && (
                <p className="text-[#6b7280] text-center text-sm">
                  נושא נבחר: {subject}
                </p>
              )}
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
              <ActionCard
                icon={Headphones}
                title="למידה קולית"
                description="צור פודקאסט מותאם אישית מהחומר"
                onClick={() => handleGenerate('podcast')}
                disabled={tokenCount <= 0}
              />
              <ActionCard
                icon={GraduationCap}
                title="בחינה חכמה"
                description="צור מבחן תרגול אינטראקטיבי"
                onClick={() => handleGenerate('test')}
                disabled={tokenCount <= 0}
              />
              <ActionCard
                icon={FileText}
                title="סיכום מהיר"
                description="קבל סיכום תמציתי של החומר"
                onClick={() => handleGenerate('summary')}
                disabled={tokenCount <= 0}
              />
            </div>
          </div>

          <div className="border-t border-[#eaecef] pt-12">
            <FeatureVoting />
          </div>
        </div>
      )}

      {isProcessing && <ProcessingAnimation type={processingType} />}

      {showPodcast && (
        <PodcastPlayer
          audioUrl={audioUrl}
          onClose={() => setShowPodcast(false)}
        />
      )}

      {showTest && (
        <TestQuestions
          questions={questions}
          onClose={() => setShowTest(false)}
        />
      )}

      {showSummary && (
        <SummaryModal
          isOpen={showSummary}
          onClose={() => setShowSummary(false)}
          summary={summary}
        />
      )}

      {showPricing && (
        <PricingModal
          isOpen={showPricing}
          onClose={() => setShowPricing(false)}
          onSelectPlan={() => {
            setShowPricing(false);
          }}
        />
      )}

      {showAuth && (
        <AuthModal
          isOpen={showAuth}
          onClose={() => {
            setShowAuth(false);
            setPendingGenerationType(null);
          }}
          reason="generation"
          onSuccess={() => {
            setShowAuth(false);
            if (pendingGenerationType) {
              handleGenerate(pendingGenerationType);
              setPendingGenerationType(null);
            }
          }}
        />
      )}
    </main>
  );
};

export default MainContent;