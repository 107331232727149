import React from 'react';
import { hebrewText } from '../lib/hebrew';

const Testimonials: React.FC = () => {
  return (
    <div className="w-full bg-white relative" dir="rtl">
      <div className="max-w-5xl mx-auto px-4 sm:px-6">
        <div className="w-full h-px bg-[#eaecef] mb-16" />
        <div className="relative overflow-hidden py-8 bg-gradient-to-l from-[#7e41cc]/5 via-transparent to-[#7e41cc]/5" style={{ marginLeft: 'calc(-50vw + 50%)', marginRight: 'calc(-50vw + 50%)', width: '100vw' }}>
          <div
            className="flex whitespace-nowrap"
            style={{
              animation: 'scroll 12s linear infinite',
            }}
          >
            {[...hebrewText.students.testimonials, ...hebrewText.students.testimonials, ...hebrewText.students.testimonials].map((text, index) => (
              <div
                key={index}
                className="inline-block px-12 text-2xl md:text-3xl font-serif text-[#1a1825]"
              >
                {text}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;