import React from 'react';
import { X, Check, CreditCard } from 'lucide-react';
import { hebrewText } from '../lib/hebrew';

interface PricingModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSelectPlan: (plan: string) => void;
}

const PricingModal: React.FC<PricingModalProps> = ({ isOpen, onClose, onSelectPlan }) => {
  if (!isOpen) return null;

  const plans = [
    {
      name: hebrewText.pricing.plans.free.name,
      price: hebrewText.pricing.plans.free.price,
      features: hebrewText.pricing.plans.free.features,
      buttonText: hebrewText.pricing.plans.free.buttonText,
      current: true,
    },
    {
      name: hebrewText.pricing.plans.pro.name,
      price: hebrewText.pricing.plans.pro.price,
      features: hebrewText.pricing.plans.pro.features,
      buttonText: hebrewText.pricing.plans.pro.buttonText,
      highlight: true,
    },
    {
      name: hebrewText.pricing.plans.champion.name,
      price: hebrewText.pricing.plans.champion.price,
      features: hebrewText.pricing.plans.champion.features,
      buttonText: hebrewText.pricing.plans.champion.buttonText,
    },
  ];

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-end sm:items-center justify-center z-50">
      <div className="bg-white w-full sm:rounded-[28px] sm:max-w-4xl sm:max-h-[90vh] h-[90vh] sm:h-auto flex flex-col rounded-t-[28px] safe-bottom sm:safe-bottom-0" dir="rtl">
        <div className="p-4 sm:p-6 border-b border-[#eaecef] flex justify-between items-center sticky top-0 bg-white z-10 rounded-t-[28px] safe-top sm:safe-top-0">
          <h2 className="text-xl sm:text-2xl font-miriam font-semibold text-[#1a1825]">{hebrewText.pricing.title}</h2>
          <button 
            onClick={onClose}
            className="p-2 -m-2 text-[#6b7280] hover:text-[#1a1825] focus:outline-none focus:ring-2 focus:ring-[#7e41cc]/50 rounded-full"
          >
            <X className="h-6 w-6" />
          </button>
        </div>

        <div className="overflow-y-auto p-4 sm:p-8 flex-1 smooth-scroll">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 sm:gap-8">
            {plans.map((plan) => (
              <div
                key={plan.name}
                className={`rounded-[28px] border transition-all flex flex-col ${
                  plan.highlight
                    ? 'border-[#7e41cc] shadow-lg shadow-[#7e41cc]/10 md:scale-105'
                    : 'border-[#eaecef] hover:border-[#7e41cc]/50'
                }`}
              >
                <div className="p-6 flex-1 flex flex-col">
                  <h3 className="text-xl font-semibold text-[#1a1825] mb-2">{plan.name}</h3>
                  <div className="text-3xl font-bold text-[#1a1825] mb-4">{plan.price}</div>
                  <ul className="space-y-3 mb-6 flex-1">
                    {plan.features.map((feature, index) => (
                      <li key={index} className="flex items-center gap-2">
                        <Check className="w-5 h-5 text-[#7e41cc] flex-shrink-0" />
                        <span className="text-[#6b7280]">{feature}</span>
                      </li>
                    ))}
                  </ul>
                  <button
                    onClick={() => onSelectPlan(plan.name)}
                    className={`w-full py-3 px-6 rounded-xl font-medium transition-all transform ${
                      plan.current
                        ? 'bg-[#f3effa] text-[#7e41cc] cursor-default'
                        : plan.highlight
                        ? 'bg-gradient-to-l from-[#7e41cc] to-[#a87ee6] text-white hover:from-[#6935a9] hover:to-[#9165d7] hover:scale-105 active:scale-95'
                        : 'bg-[#7e41cc] text-white hover:bg-[#6935a9] hover:scale-105 active:scale-95'
                    } focus:outline-none focus:ring-2 focus:ring-[#7e41cc]/50 focus:ring-offset-2`}
                    disabled={plan.current}
                  >
                    {plan.buttonText}
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingModal;