import React, { useState } from 'react';
import { CreditCard, ArrowRight, Send } from 'lucide-react';
import { sendNotificationEmail } from '../lib/email';

interface ComingSoonPageProps {
  onBack: () => void;
}

const ComingSoonPage: React.FC<ComingSoonPageProps> = ({ onBack }) => {
  const [email, setEmail] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError('נא להזין כתובת אימייל תקינה');
      return;
    }

    setIsSubmitting(true);
    setError('');

    try {
      await sendNotificationEmail(email);
      setIsSubmitted(true);
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'ההרשמה נכשלה. נא לנסות שוב.';
      setError(errorMessage);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen bg-white flex flex-col" dir="rtl">
      <div className="max-w-5xl mx-auto px-6 py-4 w-full">
        <button
          onClick={onBack}
          className="inline-flex items-center text-[#6b7280] hover:text-[#1a1825] transition-colors"
        >
          <ArrowRight className="w-5 h-5 ml-2" />
          חזרה לדף הבית
        </button>
      </div>

      <div className="flex-1 flex items-center justify-center p-4">
        <div className="max-w-lg w-full text-center">
          <div className="mb-8 relative">
            <CreditCard
              className="w-32 h-32 text-[#7e41cc] mx-auto"
              style={{
                animation: 'dance 3s ease-in-out infinite',
              }}
            />
          </div>
          <h1 className="text-4xl font-miriam font-bold text-[#1a1825] mb-4">
            תכונות פרימיום בקרוב!
          </h1>
          <p className="text-xl text-[#6b7280] mb-12">
            אנחנו עובדים במרץ כדי להביא לכם תכונות חדשות ומדהימות. הירשמו כדי להיות הראשונים לדעת כשהן מוכנות!
          </p>

          {!isSubmitted ? (
            <form onSubmit={handleSubmit} className="max-w-md mx-auto space-y-4">
              <div className="flex gap-2">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="px-6 py-3 bg-[#7e41cc] text-white rounded-lg hover:bg-[#6935a9] transition-colors flex items-center gap-2 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {isSubmitting ? (
                    'שולח...'
                  ) : (
                    <>
                      עדכנו אותי
                      <Send className="w-4 h-4" />
                    </>
                  )}
                </button>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="הזן את האימייל שלך"
                  className="flex-1 px-4 py-3 rounded-lg border border-[#eaecef] focus:outline-none focus:ring-2 focus:ring-[#7e41cc]/20 text-right"
                  required
                />
              </div>
              {error && (
                <p className="text-red-500 text-sm mt-2">{error}</p>
              )}
            </form>
          ) : (
            <div className="bg-[#7e41cc]/10 p-6 rounded-lg border border-[#7e41cc]/20">
              <h3 className="text-xl font-semibold text-[#7e41cc] mb-2">
                תודה על ההתעניינות!
              </h3>
              <p className="text-[#6b7280]">
                שלחנו לך אימייל אישור. עקוב אחר תיבת הדואר הנכנס שלך לעדכונים!
              </p>
            </div>
          )}
        </div>
      </div>

      <style>
        {`
          @keyframes dance {
            0%, 100% {
              transform: rotate(-5deg) translateY(0);
            }
            25% {
              transform: rotate(5deg) translateY(-10px);
            }
            50% {
              transform: rotate(-5deg) translateY(0);
            }
            75% {
              transform: rotate(5deg) translateY(-5px);
            }
          }
        `}
      </style>
    </div>
  );
};

export default ComingSoonPage;