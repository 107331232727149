import { useState, useEffect } from 'react';
import { doc, getDoc, setDoc, updateDoc, Timestamp } from 'firebase/firestore';
import { db } from '../lib/firebase';
import type { User } from '../types/firebase';

export const useUserStats = (userId: string | undefined) => {
  const [tokenCount, setTokenCount] = useState(3);
  const [timeToReset, setTimeToReset] = useState<string>('');
  const [loading, setLoading] = useState(true);
  const [maxTokens, setMaxTokens] = useState(3);
  const [planType, setPlanType] = useState('free');
  const [totalGenerations, setTotalGenerations] = useState(0);

  useEffect(() => {
    const fetchStats = async () => {
      if (!userId) {
        setTokenCount(3);
        setTimeToReset('');
        setLoading(false);
        return;
      }

      try {
        const userRef = doc(db, 'users', userId);
        const userSnap = await getDoc(userRef);
        
        if (userSnap.exists()) {
          const userData = userSnap.data() as User;
          const lastReset = userData.lastTokenReset?.toDate() || new Date();
          const nextReset = new Date(lastReset.getTime() + 7 * 24 * 60 * 60 * 1000);
          const now = new Date();
          
          // Set plan type and max tokens
          setPlanType(userData.subscription);
          const maxTokensForPlan = userData.subscription === 'champion' ? 100 : 
                                 userData.subscription === 'pro' ? 30 : 3;
          setMaxTokens(maxTokensForPlan);
          
          // Set total generations
          setTotalGenerations(userData.totalGenerations || 0);
          
          // Check if tokens need to be reset
          if (now >= nextReset) {
            await updateDoc(userRef, {
              tokenCount: maxTokensForPlan,
              lastTokenReset: Timestamp.now()
            });
            
            setTokenCount(maxTokensForPlan);
            setTimeToReset('7 ימים');
          } else {
            setTokenCount(userData.tokensLeft);
            
            // Calculate time until next reset
            const timeUntilReset = nextReset.getTime() - now.getTime();
            const daysUntilReset = Math.ceil(timeUntilReset / (1000 * 60 * 60 * 24));
            setTimeToReset(`${daysUntilReset} ימים`);
          }
        } else {
          // Create new user document if it doesn't exist
          const newUser = {
            subscription: 'free' as const,
            tokensLeft: 3,
            totalGenerations: 0,
            lastTokenReset: Timestamp.now(),
            createdAt: Timestamp.now()
          };
          
          await setDoc(userRef, newUser);
          setTokenCount(3);
          setTimeToReset('7 ימים');
        }
      } catch (error) {
        console.error('Error fetching user stats:', error);
        setTokenCount(3);
      } finally {
        setLoading(false);
      }
    };

    fetchStats();
  }, [userId]);

  const decrementToken = async (): Promise<boolean> => {
    if (!userId) {
      if (tokenCount > 0) {
        setTokenCount(prev => prev - 1);
        return true;
      }
      return false;
    }

    try {
      const userRef = doc(db, 'users', userId);
      const userSnap = await getDoc(userRef);

      if (userSnap.exists()) {
        const userData = userSnap.data() as User;
        
        if (userData.tokensLeft > 0) {
          await updateDoc(userRef, {
            tokensLeft: userData.tokensLeft - 1,
            totalGenerations: (userData.totalGenerations || 0) + 1
          });
          
          setTokenCount(userData.tokensLeft - 1);
          setTotalGenerations(prev => prev + 1);
          return true;
        }
      }
      
      return false;
    } catch (error) {
      console.error('Error decrementing token:', error);
      return false;
    }
  };

  return {
    tokenCount,
    timeToReset,
    loading,
    decrementToken,
    maxTokens,
    planType,
    totalGenerations
  };
};

export type UseUserStatsReturn = ReturnType<typeof useUserStats>;