import React, { useState } from 'react';
import { Upload, FileText, PenLine } from 'lucide-react';
import { hebrewText } from '../lib/hebrew';
import InteractiveMascot from './InteractiveMascot';

interface HeroProps {
  onFileSelect: () => void;
  onDrop: (event: React.DragEvent) => void;
  onDragOver: (event: React.DragEvent) => void;
  mascotRef: React.RefObject<HTMLDivElement>;
  onTopicSubmit: (topic: string) => void;
}

const Hero: React.FC<HeroProps> = ({ onFileSelect, onDrop, onDragOver, mascotRef, onTopicSubmit }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [isSubjectMode, setIsSubjectMode] = useState(false);
  const [subject, setSubject] = useState('');

  const handleDragEnter = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    if (e.currentTarget.contains(e.relatedTarget as Node)) {
      return;
    }
    setIsDragging(false);
  };

  const handleDrop = (e: React.DragEvent) => {
    setIsDragging(false);
    onDrop(e);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (subject.trim()) {
      onTopicSubmit(subject.trim());
    }
  };

  return (
    <div className="flex flex-col lg:grid lg:grid-cols-2 gap-8 items-center py-6 sm:py-12" dir="rtl">
      {/* Hero Text - First on mobile */}
      <div className="text-right order-1 lg:order-1 w-full">
        <h1 className="text-2xl sm:text-4xl font-miriam font-bold text-[#1a1825] mb-4 leading-tight">
          {hebrewText.home.title}
        </h1>
        <p className="text-lg sm:text-xl text-[#6b7280] mb-6 sm:mb-8">
          {hebrewText.home.subtitle}
        </p>

        {/* Mode Toggle */}
        <div className="flex justify-center mb-6">
          <div className="bg-white rounded-xl p-1 shadow-sm border border-[#eaecef] inline-flex">
            <button
              onClick={() => setIsSubjectMode(false)}
              className={`flex items-center gap-2 px-4 py-2 rounded-lg transition-all duration-300 ${
                !isSubjectMode 
                  ? 'border-2 border-[#7e41cc] text-[#7e41cc] bg-[#7e41cc]/5' 
                  : 'text-[#6b7280] hover:text-[#1a1825] border-2 border-transparent'
              }`}
            >
              <FileText className="w-4 h-4" />
              <span>העלאת קובץ</span>
            </button>
            <button
              onClick={() => setIsSubjectMode(true)}
              className={`flex items-center gap-2 px-4 py-2 rounded-lg transition-all duration-300 ${
                isSubjectMode 
                  ? 'border-2 border-[#7e41cc] text-[#7e41cc] bg-[#7e41cc]/5' 
                  : 'text-[#6b7280] hover:text-[#1a1825] border-2 border-transparent'
              }`}
            >
              <PenLine className="w-4 h-4" />
              <span>כתיבת נושא</span>
            </button>
          </div>
        </div>

        {/* Content Box */}
        <div
          className={`upload-box bg-white rounded-2xl border-2 border-dashed p-6 sm:p-8 text-center transition-all duration-300 h-[220px] sm:h-[240px] flex items-center justify-center ${
            isDragging || isHovering 
              ? 'border-[#7e41cc] bg-[#7e41cc]/10 scale-[1.02] shadow-[0_0_30px_rgba(126,65,204,0.3)]' 
              : 'border-[#eaecef] hover:border-[#7e41cc] hover:bg-[#7e41cc]/5'
          }`}
          onDrop={!isSubjectMode ? handleDrop : undefined}
          onDragOver={!isSubjectMode ? onDragOver : undefined}
          onDragEnter={!isSubjectMode ? handleDragEnter : undefined}
          onDragLeave={!isSubjectMode ? handleDragLeave : undefined}
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
        >
          <div className="flex flex-col items-center w-full">
            <div className={`w-12 h-12 rounded-xl flex items-center justify-center mb-4 transition-all duration-300 ${
              isDragging || isHovering ? 'bg-[#7e41cc]/30 scale-110' : 'bg-[#7e41cc]/10'
            }`}>
              {isSubjectMode ? (
                <PenLine className={`w-6 h-6 text-[#7e41cc] transition-transform duration-300 ${
                  isHovering ? 'scale-110' : ''
                }`} />
              ) : (
                <Upload className={`w-6 h-6 text-[#7e41cc] transition-transform duration-300 ${
                  isDragging || isHovering ? 'scale-110' : ''
                }`} />
              )}
            </div>
            <h2 className="text-lg sm:text-xl font-semibold mb-4">
              {isSubjectMode ? 'הזן את הנושא שתרצה ללמוד' : hebrewText.home.uploadTitle}
            </h2>
            {isSubjectMode ? (
              <form onSubmit={handleSubmit} className="w-full max-w-[280px] sm:max-w-sm">
                <input
                  type="text"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  placeholder="לדוגמה: מלחמת העולם השנייה"
                  className="w-full px-4 py-2 rounded-lg border border-[#eaecef] focus:outline-none focus:ring-2 focus:ring-[#7e41cc]/20 text-right mb-4 text-sm sm:text-base"
                />
                <button
                  type="submit"
                  className="px-6 py-2 bg-[#7e41cc] text-white rounded-lg hover:bg-[#6935a9] transition-all transform hover:scale-105 active:scale-95 text-sm sm:text-base"
                >
                  צור חומר לימוד
                </button>
              </form>
            ) : (
              <>
                <p className="text-[#6b7280] mb-4 text-sm sm:text-base">{hebrewText.home.uploadSubtitle}</p>
                <button
                  onClick={onFileSelect}
                  className="px-4 sm:px-6 py-2 sm:py-3 bg-[#7e41cc] text-white rounded-xl hover:bg-[#6935a9] transition-all duration-300 transform hover:scale-105 active:scale-95 hover:shadow-lg text-sm sm:text-base"
                >
                  {hebrewText.home.browseFiles}
                </button>
              </>
            )}
          </div>
        </div>

        <div className="grid grid-cols-3 gap-3 sm:gap-4 mt-6 sm:mt-8">
          <FeatureItem
            icon="🎧"
            title={hebrewText.home.features.audio}
          />
          <FeatureItem
            icon="📝"
            title={hebrewText.home.features.testing}
          />
          <FeatureItem
            icon="📚"
            title={hebrewText.home.features.summary}
          />
        </div>
      </div>

      {/* Mascot - Last on mobile */}
      <div className="relative order-3 lg:order-2 -mx-4 sm:mx-0 flex items-center justify-center">
        <div className="relative bg-[#f3effa] rounded-[28px] p-6 sm:p-8 aspect-square w-[340px] sm:w-[420px]">
          <div className="absolute inset-0 rounded-[28px] bg-[#7e41cc]/10 blur-2xl"></div>
          <div ref={mascotRef} className="relative w-full h-full">
            <InteractiveMascot 
              containerRef={mascotRef} 
              isExcited={isDragging || isHovering}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const FeatureItem: React.FC<{ icon: string; title: string }> = ({ icon, title }) => {
  return (
    <div className="flex flex-col items-center bg-white rounded-xl p-3 sm:p-4 shadow-sm hover:shadow-md transition-shadow">
      <span className="text-xl sm:text-2xl mb-1 sm:mb-2">{icon}</span>
      <span className="text-xs sm:text-sm font-medium text-[#1a1825] text-center line-clamp-2">{title}</span>
    </div>
  );
};

export default Hero;