import React, { useState } from 'react';
import { X, Mail } from 'lucide-react';
import { signInWithGoogle, signUpWithEmail, signInWithEmail } from '../lib/auth';

interface AuthModalProps {
  isOpen: boolean;
  onClose: () => void;
  reason?: 'generation' | 'upgrade';
  onSuccess?: () => void;
}

const AuthModal: React.FC<AuthModalProps> = ({ isOpen, onClose, reason, onSuccess }) => {
  const [isSignUp, setIsSignUp] = useState(true); // Changed default to true
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showVerification, setShowVerification] = useState(false);

  if (!isOpen) return null;

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');

    try {
      if (isSignUp) {
        await signUpWithEmail(email, password);
        setShowVerification(true);
      } else {
        await signInWithEmail(email, password);
        onSuccess?.();
        onClose();
      }
    } catch (err: any) {
      setError(err.message);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      await signInWithGoogle();
      onSuccess?.();
      onClose();
    } catch (err: any) {
      setError(err.message);
    }
  };

  if (showVerification) {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
        <div className="bg-white w-full max-w-md rounded-2xl overflow-hidden" dir="rtl">
          <div className="p-4 border-b border-[#eaecef] flex justify-between items-center">
            <h2 className="text-xl font-semibold text-[#1a1825]">אימות אימייל</h2>
            <button
              onClick={onClose}
              className="p-2 text-[#6b7280] hover:text-[#1a1825] transition-colors"
            >
              <X className="w-6 h-6" />
            </button>
          </div>

          <div className="p-6 text-center">
            <div className="w-24 h-24 mx-auto mb-6 relative">
              <div className="absolute inset-0 bg-[#7e41cc]/10 rounded-2xl animate-pulse"></div>
              <div className="relative bg-gradient-to-br from-[#7e41cc] to-[#a87ee6] rounded-2xl w-full h-full flex items-center justify-center transform hover:scale-105 transition-transform dancing-envelope">
                <Mail className="w-12 h-12 text-white" />
              </div>
            </div>
            
            <h3 className="text-xl font-semibold mb-2">נשלח אימייל אימות!</h3>
            <p className="text-[#6b7280] mb-4">
              שלחנו אימייל אימות לכתובת {email}.<br />
              אנא בדוק את תיבת הדואר הנכנס שלך ולחץ על הקישור לאימות החשבון.
            </p>
            
            <button
              onClick={onClose}
              className="w-full py-3 px-4 bg-[#7e41cc] text-white rounded-lg hover:bg-[#6935a9] transition-colors"
            >
              הבנתי
            </button>
          </div>

          <style>{`
            @keyframes dance {
              0%, 100% {
                transform: rotate(-5deg) translateY(0);
              }
              25% {
                transform: rotate(5deg) translateY(-10px);
              }
              50% {
                transform: rotate(-5deg) translateY(0);
              }
              75% {
                transform: rotate(5deg) translateY(-5px);
              }
            }

            .dancing-envelope {
              animation: dance 3s ease-in-out infinite;
            }
          `}</style>
        </div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white w-full max-w-md rounded-2xl overflow-hidden" dir="rtl">
        <div className="p-4 border-b border-[#eaecef] flex justify-between items-center">
          <h2 className="text-xl font-semibold text-[#1a1825]">{isSignUp ? 'צור חשבון' : 'התחבר'}</h2>
          <button
            onClick={onClose}
            className="p-2 text-[#6b7280] hover:text-[#1a1825] transition-colors"
          >
            <X className="w-6 h-6" />
          </button>
        </div>

        <div className="p-6">
          {reason === 'generation' && (
            <div className="bg-[#f3effa] border border-[#7e41cc]/20 rounded-lg p-4 mb-6">
              <p className="text-[#1a1825]">
                כדי להמשיך ליצור חומרי לימוד, אנא צור חשבון דיגיטלי.
                זה יעזור לך לעקוב יותר ולשמור על ההתקדמות שלך.
              </p>
            </div>
          )}

          <button
            onClick={handleGoogleSignIn}
            className="w-full py-3 px-4 border border-[#eaecef] rounded-lg flex items-center justify-center gap-2 hover:bg-gray-50 transition-colors mb-4"
          >
            <img src="https://www.google.com/favicon.ico" alt="Google" className="w-5 h-5" />
            <span>המשך עם Google</span>
          </button>

          <div className="relative my-6">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-[#eaecef]"></div>
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="px-2 bg-white text-[#6b7280]">או המשך עם אימייל</span>
            </div>
          </div>

          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-[#1a1825] mb-1">
                אימייל
              </label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full px-4 py-2 border border-[#eaecef] rounded-lg focus:outline-none focus:ring-2 focus:ring-[#7e41cc]/20"
                required
              />
            </div>

            <div>
              <label htmlFor="password" className="block text-sm font-medium text-[#1a1825] mb-1">
                סיסמה
              </label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full px-4 py-2 border border-[#eaecef] rounded-lg focus:outline-none focus:ring-2 focus:ring-[#7e41cc]/20"
                required
              />
            </div>

            {error && (
              <p className="text-red-500 text-sm">{error}</p>
            )}

            <button
              type="submit"
              className="w-full py-3 px-4 bg-[#7e41cc] text-white rounded-lg hover:bg-[#6935a9] transition-colors"
            >
              {isSignUp ? 'הרשמה' : 'התחברות'}
            </button>
          </form>

          <p className="mt-4 text-center text-sm text-[#6b7280]">
            {isSignUp ? 'כבר יש לך חשבון?' : 'אין לך חשבון?'}{' '}
            <button
              onClick={() => setIsSignUp(!isSignUp)}
              className="text-[#7e41cc] hover:text-[#6935a9] transition-colors"
            >
              {isSignUp ? 'התחבר' : 'הירשם'}
            </button>
          </p>
        </div>
      </div>
    </div>
  );
};

export default AuthModal;