import React, { useState, useRef, useEffect } from 'react';
import { LogOut, User, ChevronDown } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { signOut } from '../lib/auth';

interface UserMenuProps {
  onSignInClick: () => void;
}

const UserMenu: React.FC<UserMenuProps> = ({ onSignInClick }) => {
  const { user } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleSignOut = async () => {
    try {
      await signOut();
      setIsOpen(false);
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  if (!user) {
    return (
      <button
        onClick={onSignInClick}
        className="px-4 sm:px-6 py-2 bg-[#7e41cc] text-white text-sm sm:text-base rounded-lg hover:bg-[#6935a9] transition-all transform hover:scale-105 active:scale-95"
      >
        התחבר / הירשם
      </button>
    );
  }

  return (
    <div className="relative" ref={menuRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center gap-2 hover:bg-[#f9fafb] p-2 rounded-lg transition-colors"
      >
        <div className="w-8 h-8 rounded-full bg-[#7e41cc]/10 flex items-center justify-center">
          <User className="w-4 h-4 text-[#7e41cc]" />
        </div>
        <span className="hidden sm:block text-sm text-[#1a1825] max-w-[120px] truncate">
          {user.email}
        </span>
        <ChevronDown className={`w-4 h-4 text-[#6b7280] transition-transform ${isOpen ? 'rotate-180' : ''}`} />
      </button>

      {isOpen && (
        <div className="absolute left-0 mt-2 w-48 bg-white rounded-lg shadow-lg border border-[#eaecef] py-1 z-50">
          <Link
            to="/profile"
            className="block px-4 py-2 text-sm text-[#1a1825] hover:bg-[#f9fafb] transition-colors"
            onClick={() => setIsOpen(false)}
          >
            הפרופיל שלי
          </Link>
          <button
            onClick={handleSignOut}
            className="w-full text-right px-4 py-2 text-sm text-red-600 hover:bg-red-50 transition-colors"
          >
            התנתק
          </button>
        </div>
      )}
    </div>
  );
};

export default UserMenu;