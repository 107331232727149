import React, { useState, useEffect } from 'react';
import { Headphones, BookOpen, GraduationCap } from 'lucide-react';
import { hebrewText } from '../lib/hebrew';
import ProgressBar from './ProgressBar';

interface ProcessingAnimationProps {
  type?: 'podcast' | 'test' | 'summary';
}

const ProcessingAnimation: React.FC<ProcessingAnimationProps> = ({ type = 'podcast' }) => {
  const [messageIndex, setMessageIndex] = useState(0);
  const [displayedMessage, setDisplayedMessage] = useState('');
  const [isTyping, setIsTyping] = useState(true);
  const [progress, setProgress] = useState(0);

  const messages = {
    podcast: hebrewText.processing.podcast,
    test: hebrewText.processing.test,
    summary: hebrewText.processing.summary
  };

  const IconComponent = {
    podcast: Headphones,
    test: GraduationCap,
    summary: BookOpen
  }[type];

  useEffect(() => {
    const totalSteps = messages[type].length;
    
    // Adjusted timing calculations
    const getProcessingTime = () => {
      switch (type) {
        case 'podcast':
          return 25000; // 25 seconds for podcast
        case 'summary':
          return 18000; // 18 seconds for summary
        case 'test':
          return 19000; // 19 seconds for test (added 7 seconds)
        default:
          return 10000;
      }
    };

    const totalTime = getProcessingTime();
    const interval = 50; // Update every 50ms
    const messageChangeInterval = totalTime / totalSteps;
    const incrementPerInterval = (100 / (totalTime / interval));

    let currentProgress = 0;
    const progressInterval = setInterval(() => {
      currentProgress += incrementPerInterval;
      if (currentProgress >= 100) {
        currentProgress = 100;
        clearInterval(progressInterval);
      }
      setProgress(Math.min(99, currentProgress));
    }, interval);

    // Message change timer
    const messageTimer = setInterval(() => {
      setMessageIndex(prev => (prev + 1) % messages[type].length);
    }, messageChangeInterval);

    return () => {
      clearInterval(progressInterval);
      clearInterval(messageTimer);
    };
  }, [type]);

  useEffect(() => {
    const currentMessage = messages[type][messageIndex];
    let charIndex = 0;

    const typeInterval = setInterval(() => {
      if (charIndex <= currentMessage.length) {
        setDisplayedMessage(currentMessage.slice(0, charIndex));
        charIndex++;
      } else {
        clearInterval(typeInterval);
        setIsTyping(false);
        setTimeout(() => {
          setIsTyping(true);
        }, 1000);
      }
    }, 50);

    return () => clearInterval(typeInterval);
  }, [messageIndex, type]);

  return (
    <div className="flex flex-col items-center justify-center py-6 sm:py-8 space-y-8 overflow-hidden" dir="rtl">
      <style>
        {`
          @keyframes pulse {
            0%, 100% { opacity: 0.4; }
            50% { opacity: 0.8; }
          }

          @keyframes bounce {
            0%, 100% { transform: translateY(0); }
            50% { transform: translateY(-8px); }
          }

          .cursor::after {
            content: '|';
            opacity: 0;
            animation: cursor 1s infinite;
            margin-right: 2px;
          }

          @keyframes cursor {
            0%, 100% { opacity: 0; }
            50% { opacity: 1; }
          }
        `}
      </style>

      <div className="relative w-full max-w-sm aspect-[2/1] bg-white rounded-2xl">
        <div className="absolute inset-0 flex flex-col items-center justify-center p-4 sm:p-6">
          <div 
            className="relative"
            style={{ animation: 'bounce 2s ease-in-out infinite' }}
          >
            <div 
              className="absolute inset-0 bg-[#7e41cc] rounded-full opacity-10 blur-lg"
              style={{ animation: 'pulse 2s ease-in-out infinite' }}
            />
            <div className="relative z-10 w-14 h-14 sm:w-16 sm:h-16 rounded-full bg-white shadow-lg flex items-center justify-center">
              <IconComponent className="w-7 h-7 sm:w-8 sm:h-8 text-[#7e41cc]" />
            </div>
          </div>

          <div className="mt-4 sm:mt-6 text-center w-full">
            <p className={`text-base sm:text-lg font-medium text-[#1a1825] min-h-[24px] sm:min-h-[28px] ${isTyping ? 'cursor' : ''}`}>
              {displayedMessage}
            </p>
            <div className="mt-4 w-full max-w-xs mx-auto space-y-2">
              <ProgressBar progress={progress} />
              <p className="text-sm text-[#6b7280] text-center">{Math.round(progress)}%</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProcessingAnimation;