import React, { useRef, useState, useEffect } from 'react';
import { X, Play, Pause, Download } from 'lucide-react';

interface PodcastPlayerProps {
  audioUrl: string;
  onClose: () => void;
}

const PodcastPlayer: React.FC<PodcastPlayerProps> = ({ audioUrl, onClose }) => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [scale, setScale] = useState(1);
  const animationRef = useRef<number>();

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.addEventListener('loadedmetadata', () => {
        setDuration(audioRef.current?.duration || 0);
      });
    }

    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const animate = () => {
      if (audioRef.current) {
        setCurrentTime(audioRef.current.currentTime);
        const newScale = 1 + (Math.sin(Date.now() / 500) + 1) * 0.1;
        setScale(isPlaying ? newScale : 1);
      }
      animationRef.current = requestAnimationFrame(animate);
    };

    if (isPlaying) {
      animationRef.current = requestAnimationFrame(animate);
    } else {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    }

    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [isPlaying]);

  const togglePlayPause = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleTimeUpdate = (value: number) => {
    if (audioRef.current) {
      audioRef.current.currentTime = value;
      setCurrentTime(value);
    }
  };

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  const handleDownload = async () => {
    try {
      const response = await fetch(audioUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'studymate-podcast.mp3';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading podcast:', error);
      alert('אירעה שגיאה בהורדת הפודקאסט. אנא נסה שוב.');
    }
  };

  const progressPercentage = (currentTime / duration) * 100;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white w-full max-w-md rounded-lg overflow-hidden" dir="rtl">
        <div className="p-4 sm:p-6 flex justify-between items-center border-b border-[#eaecef]">
          <h2 className="text-xl font-semibold text-[#1a1825]">פודקאסט</h2>
          <div className="flex items-center gap-2">
            <button
              onClick={handleDownload}
              className="p-2 text-[#7e41cc] hover:text-[#6935a9] focus:outline-none focus:ring-2 focus:ring-[#7e41cc]/50 rounded-lg transition-colors"
              title="הורד פודקאסט"
            >
              <Download className="h-5 w-5" />
            </button>
            <button 
              onClick={onClose} 
              className="p-2 text-[#6b7280] hover:text-[#1a1825] focus:outline-none focus:ring-2 focus:ring-[#7e41cc]/50 rounded-lg"
            >
              <X className="h-5 w-5" />
            </button>
          </div>
        </div>

        <div className="p-6 sm:p-8">
          <div className="flex justify-center mb-8">
            <div className="relative w-32 h-32">
              <div
                className="absolute inset-0 bg-gradient-to-br from-[#7e41cc] to-[#a87ee6] rounded-full opacity-75 blur-lg"
                style={{
                  transform: `scale(${scale * 1.2})`,
                  transition: 'transform 0.2s ease-out',
                }}
              />
              <button
                onClick={togglePlayPause}
                className="relative w-full h-full rounded-full bg-gradient-to-br from-[#7e41cc] to-[#a87ee6] flex items-center justify-center text-white hover:from-[#6935a9] hover:to-[#9165d7] transition-all focus:outline-none focus:ring-2 focus:ring-[#7e41cc]/50 focus:ring-offset-2"
                style={{
                  transform: `scale(${scale})`,
                  transition: 'transform 0.2s ease-out',
                }}
              >
                {isPlaying ? (
                  <Pause className="h-12 w-12" />
                ) : (
                  <Play className="h-12 w-12 mr-1" />
                )}
              </button>
            </div>
          </div>

          <div className="space-y-4">
            <div className="relative w-full">
              <input
                type="range"
                min={0}
                max={duration}
                value={duration - currentTime}
                onChange={(e) => handleTimeUpdate(duration - Number(e.target.value))}
                className="w-full appearance-none bg-gray-200 rounded-lg h-2 rtl-progress"
                style={{
                  background: `linear-gradient(to left, #e5e7eb ${100 - progressPercentage}%, #7e41cc ${100 - progressPercentage}%)`,
                }}
              />
            </div>
            <style>{`
              .rtl-progress {
                direction: rtl;
              }
              .rtl-progress::-webkit-slider-thumb {
                -webkit-appearance: none;
                width: 16px;
                height: 16px;
                border-radius: 50%;
                background: #7e41cc;
                cursor: pointer;
                transition: all .2s ease-in-out;
              }
              .rtl-progress::-webkit-slider-thumb:hover {
                transform: scale(1.2);
              }
              .rtl-progress::-moz-range-thumb {
                width: 16px;
                height: 16px;
                border-radius: 50%;
                background: #7e41cc;
                cursor: pointer;
                border: none;
                transition: all .2s ease-in-out;
              }
              .rtl-progress::-moz-range-thumb:hover {
                transform: scale(1.2);
              }
              @media (max-width: 640px) {
                input[type="range"] {
                  height: 4px;
                  border-radius: 2px;
                }
                .play-button-mobile {
                  box-shadow: none !important;
                  outline: none !important;
                }
              }
            `}</style>
            <div className="flex justify-between text-sm text-[#6b7280]">
              <span>{formatTime(currentTime)}</span>
              <span>{formatTime(duration - currentTime)}</span>
            </div>
          </div>
        </div>

        <audio ref={audioRef} src={audioUrl} />
      </div>
    </div>
  );
};

export default PodcastPlayer;