import React, { useState } from 'react';
import { GraduationCap } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import AuthModal from './AuthModal';
import { useAuth } from '../hooks/useAuth';
import UserMenu from './UserMenu';

interface HeaderProps {
  minimal?: boolean;
  fileName?: string;
  onFileChange?: () => void;
}

const Header: React.FC<HeaderProps> = ({ minimal, fileName, onFileChange }) => {
  const [showAuthModal, setShowAuthModal] = useState(false);
  const { user } = useAuth();
  const navigate = useNavigate();

  const handleLogoClick = (e: React.MouseEvent) => {
    e.preventDefault();
    // Reset to homepage state
    navigate('/', { replace: true });
    // Force a page refresh to reset all states
    window.location.href = '/';
  };

  return (
    <header className="bg-white border-b border-[#eaecef]">
      <div className="max-w-5xl mx-auto px-4 sm:px-6">
        <div className="flex items-center justify-between h-16">
          <a 
            href="/"
            onClick={handleLogoClick}
            className="flex items-center gap-2 hover:opacity-80 transition-opacity"
            aria-label="StudyMate AI Homepage"
          >
            <div className="flex items-center gap-2">
              <GraduationCap className="w-6 h-6 text-primary" />
              <span className="text-lg font-semibold">StudyMate AI</span>
            </div>
          </a>

          <UserMenu onSignInClick={() => setShowAuthModal(true)} />
        </div>
      </div>

      <AuthModal
        isOpen={showAuthModal}
        onClose={() => setShowAuthModal(false)}
        onSuccess={() => setShowAuthModal(false)}
      />
    </header>
  );
}

export default Header;