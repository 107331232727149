import { Resend } from 'resend';

export async function sendNotificationEmail(email: string): Promise<void> {
  const apiKey = import.meta.env.VITE_RESEND_API_KEY;
  const fromEmail = import.meta.env.VITE_RESEND_FROM_EMAIL;

  // Validate email format
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(email)) {
    throw new Error('Invalid email address format');
  }

  // Validate API configuration
  if (!apiKey) {
    throw new Error('Email service API key is not configured');
  }

  if (!fromEmail) {
    throw new Error('Sender email is not configured');
  }

  // Initialize Resend with API key
  const resend = new Resend(apiKey);

  try {
    const { data, error } = await resend.emails.send({
      from: fromEmail,
      to: [email],
      subject: '🎉 Welcome to StudyMate AI!',
      html: `
        <!DOCTYPE html>
        <html>
          <head>
            <meta charset="utf-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>Welcome to StudyMate AI</title>
          </head>
          <body style="margin: 0; padding: 0; font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;">
            <div style="background: linear-gradient(135deg, #7e41cc, #a87ee6); padding: 40px 20px;">
              <div style="max-width: 600px; margin: 0 auto; background: white; border-radius: 16px; overflow: hidden; box-shadow: 0 20px 40px rgba(0,0,0,0.1);">
                <!-- Header -->
                <div style="background: linear-gradient(135deg, #7e41cc, #a87ee6); padding: 40px 20px; text-align: center;">
                  <h1 style="color: white; margin: 0; font-size: 32px; font-weight: 700;">Welcome to StudyMate AI!</h1>
                </div>

                <!-- Content -->
                <div style="padding: 40px 30px;">
                  <p style="color: #1a1825; font-size: 18px; line-height: 1.6; margin-bottom: 24px;">
                    Thank you for your interest in StudyMate AI's premium features! 🎉
                  </p>
                  
                  <p style="color: #6b7280; font-size: 16px; line-height: 1.6; margin-bottom: 24px;">
                    We're working hard to bring you amazing new features that will transform your learning experience:
                  </p>

                  <div style="background: #f9fafb; border-radius: 12px; padding: 24px; margin-bottom: 32px;">
                    <ul style="list-style: none; padding: 0; margin: 0;">
                      <li style="color: #1a1825; font-size: 16px; margin-bottom: 16px; display: flex; align-items: center;">
                        <span style="color: #7e41cc; margin-right: 12px;">✦</span>
                        Advanced AI-powered study tools
                      </li>
                      <li style="color: #1a1825; font-size: 16px; margin-bottom: 16px; display: flex; align-items: center;">
                        <span style="color: #7e41cc; margin-right: 12px;">✦</span>
                        Personalized learning paths
                      </li>
                      <li style="color: #1a1825; font-size: 16px; margin-bottom: 16px; display: flex; align-items: center;">
                        <span style="color: #7e41cc; margin-right: 12px;">✦</span>
                        Enhanced content generation
                      </li>
                      <li style="color: #1a1825; font-size: 16px; display: flex; align-items: center;">
                        <span style="color: #7e41cc; margin-right: 12px;">✦</span>
                        Priority access to new features
                      </li>
                    </ul>
                  </div>

                  <p style="color: #6b7280; font-size: 16px; line-height: 1.6; margin-bottom: 32px;">
                    You'll be among the first to know when these exciting features become available. Stay tuned!
                  </p>

                  <div style="text-align: center;">
                    <a href="https://studymate.ai" style="display: inline-block; background: #7e41cc; color: white; text-decoration: none; padding: 16px 32px; border-radius: 8px; font-weight: 600; transition: background 0.3s ease;">
                      Start Learning Now
                    </a>
                  </div>
                </div>

                <!-- Footer -->
                <div style="background: #f9fafb; padding: 24px; text-align: center; border-top: 1px solid #eaecef;">
                  <p style="color: #6b7280; font-size: 14px; margin: 0;">
                    © 2024 StudyMate AI. All rights reserved.
                  </p>
                </div>
              </div>
            </div>
          </body>
        </html>
      `,
    });

    if (error) {
      throw new Error(error.message);
    }

    if (!data) {
      throw new Error('No response data received from email service');
    }

    return;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(`Failed to send email: ${error.message}`);
    }
    throw new Error('An unexpected error occurred while sending the email');
  }
}