import OpenAI from 'openai';

const apiKey = import.meta.env.VITE_OPENAI_API_KEY;

if (!apiKey || apiKey === 'your-openai-api-key') {
  console.warn('OpenAI API key is not properly configured. Please add your API key to the .env file.');
}

const openai = new OpenAI({
  apiKey: apiKey,
  dangerouslyAllowBrowser: true
});

// Constants for content generation
const WORDS_PER_MINUTE = 150;
const TARGET_PODCAST_DURATION = 80; // 1 minute and 20 seconds
const MAX_PODCAST_WORDS = Math.floor(WORDS_PER_MINUTE * (TARGET_PODCAST_DURATION / 60));
const MAX_SUMMARY_WORDS = 700; // Increased to 700 words
const MAX_TOKENS = 2000;

export async function generatePodcast(text: string): Promise<string> {
  try {
    if (!apiKey || apiKey === 'your-openai-api-key') {
      throw new Error('Please configure your OpenAI API key in the .env file');
    }

    const response = await openai.chat.completions.create({
      model: "gpt-3.5-turbo",
      messages: [
        {
          role: "system",
          content: `אתה מומחה בסיכום חומרי לימוד לפודקאסט בעברית. תפקידך ליצור סיכום קולי תמציתי וברור.
          הנחיות חשובות:
          1. צור טקסט באורך ${MAX_PODCAST_WORDS} מילים בדיוק
          2. התחל עם משפט פתיחה קצר וברור
          3. סקור את כל הנקודות המרכזיות בטקסט
          4. סיים עם משפט מסכם
          5. השתמש במשפטים קצרים וברורים
          6. וודא שכל משפט מסתיים בנקודה
          7. הימנע ממילים מסובכות או ביטויים מורכבים
          8. חשוב: וודא שהסיכום מכסה את כל החומר החשוב ומסתיים בצורה טבעית`
        },
        {
          role: "user",
          content: `צור סיכום קולי של הטקסט הבא. הקפד על אורך של ${MAX_PODCAST_WORDS} מילים בדיוק:\n\n${text}`
        }
      ],
      temperature: 0.3,
      max_tokens: MAX_TOKENS
    });

    const script = response.choices[0].message.content;
    if (!script) {
      throw new Error('Failed to generate podcast script');
    }

    const speechResponse = await openai.audio.speech.create({
      model: "tts-1",
      voice: "nova",
      input: script,
      speed: 1.1,
    });

    const buffer = await speechResponse.arrayBuffer();
    const blob = new Blob([buffer], { type: 'audio/mpeg' });
    const audioUrl = URL.createObjectURL(blob);

    return audioUrl;
  } catch (error) {
    console.error('Error generating podcast:', error);
    if (error instanceof Error) {
      throw new Error(`נכשל ביצירת הסיכום הקולי: ${error.message}`);
    }
    throw new Error('נכשל ביצירת הסיכום הקולי. אנא בדוק את מפתח ה-API שלך ונסה שוב.');
  }
}

export async function generateSummary(text: string): Promise<string> {
  try {
    if (!apiKey || apiKey === 'your-openai-api-key') {
      throw new Error('Please configure your OpenAI API key in the .env file');
    }

    const response = await openai.chat.completions.create({
      model: "gpt-3.5-turbo",
      messages: [
        {
          role: "system",
          content: `אתה מומחה בסיכום חומרי לימוד בעברית. תפקידך ליצור סיכום תמציתי ומקיף של החומר.
          הנחיות חשובות:
          1. צור סיכום תמציתי באורך של ${MAX_SUMMARY_WORDS} מילים בדיוק
          2. חלק את הסיכום לנושאים ברורים עם כותרות
          3. כל כותרת תתחיל עם סימון #
          4. תחת כל כותרת, רשום נקודות עיקריות עם סימון •
          5. וודא שהסיכום מכסה את כל הנושאים החשובים מהטקסט המקורי
          6. השתמש בשפה ברורה ופשוטה
          7. סיים עם פסקת סיכום קצרה
          8. חשוב: אל תחרוג מ-${MAX_SUMMARY_WORDS} מילים
          9. וודא שכל משפט מסתיים בנקודה
          10. הימנע מקיצורים ומונחים באנגלית`
        },
        {
          role: "user",
          content: `צור סיכום מקיף של הטקסט הבא. הקפד על אורך של ${MAX_SUMMARY_WORDS} מילים בדיוק:\n\n${text}`
        }
      ],
      temperature: 0.3,
      max_tokens: MAX_TOKENS
    });

    const summary = response.choices[0].message.content;
    if (!summary) {
      throw new Error('Failed to generate summary');
    }

    return summary;
  } catch (error) {
    console.error('Error generating summary:', error);
    if (error instanceof Error) {
      throw new Error(`נכשל ביצירת הסיכום: ${error.message}`);
    }
    throw new Error('נכשל ביצירת הסיכום. אנא בדוק את מפתח ה-API שלך ונסה שוב.');
  }
}

export async function generateTest(text: string): Promise<Question[]> {
  try {
    if (!apiKey || apiKey === 'your-openai-api-key') {
      throw new Error('Please configure your OpenAI API key in the .env file');
    }

    const response = await openai.chat.completions.create({
      model: "gpt-3.5-turbo",
      messages: [
        {
          role: "system",
          content: `אתה מחנך מקצועי היוצר שאלות רב-ברירה בעברית. תפקידך ליצור בדיוק 10 שאלות מבחן איכותיות.
          הנחיות חשובות:
          1. צור בדיוק 10 שאלות אמריקאיות בעברית
          2. שאלות קצרות וממוקדות - לא יותר מ-20 מילים לשאלה
          3. תשובות קצרות וברורות - לא יותר מ-8 מילים לכל תשובה
          4. כל שאלה צריכה לבחון נקודת מפתח מהטקסט
          5. התשובות צריכות להיות חד משמעיות וברורות
          6. הימנע מניסוח מסורבל או מבלבל
          7. השתמש בשפה פשוטה ומובנת
          8. וודא שהתשובה הנכונה ניתנת להוכחה מהטקסט`
        },
        {
          role: "user",
          content: `צור 10 שאלות אמריקאיות בעברית המבוססות על הטקסט הבא. פרמט את התשובה כ-JSON עם המבנה הבא:
          {
            "questions": [
              {
                "question": "שאלה קצרה וממוקדת בעברית",
                "options": ["תשובה קצרה 1", "תשובה קצרה 2", "תשובה קצרה 3", "תשובה קצרה 4"],
                "correctAnswer": 0
              }
            ]
          }
          
          הטקסט:\n\n${text}`
        }
      ],
      temperature: 0.3,
      response_format: { type: "json_object" }
    });

    const content = response.choices[0].message.content;
    if (!content) {
      throw new Error('Failed to generate test questions');
    }

    const result = JSON.parse(content);
    return result.questions;
  } catch (error) {
    console.error('Error generating test:', error);
    if (error instanceof Error) {
      throw new Error(`נכשל ביצירת שאלות המבחן: ${error.message}`);
    }
    throw new Error('נכשל ביצירת שאלות המבחן. אנא בדוק את מפתח ה-API שלך ונסה שוב.');
  }
}

export interface Question {
  question: string;
  options: string[];
  correctAnswer: number;
}