import React from 'react';
import { LucideIcon } from 'lucide-react';

interface ActionCardProps {
  icon: LucideIcon;
  title: string;
  description: string;
  onClick: () => void;
  disabled?: boolean;
}

const ActionCard: React.FC<ActionCardProps> = ({
  icon: Icon,
  title,
  description,
  onClick,
  disabled = false
}) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`group bg-white p-6 rounded-lg border w-full transition-all duration-200 ${
        disabled
          ? 'border-[#eaecef] opacity-50 cursor-not-allowed'
          : 'border-[#eaecef] hover:border-[#7e41cc] active:scale-95 sm:hover:scale-105 transform'
      } focus:outline-none focus:ring-2 focus:ring-[#7e41cc]/50 focus:ring-offset-2`}
      dir="rtl"
    >
      <div className="flex flex-col items-center text-center">
        <div className="transform transition-transform duration-200 group-hover:-rotate-12">
          <Icon className={`h-8 w-8 ${disabled ? 'text-gray-400' : 'text-[#7e41cc]'}`} />
        </div>
        <h3 className={`font-semibold mb-2 ${disabled ? 'text-gray-400' : 'text-[#1a1825]'}`}>
          {title}
        </h3>
        <p className={`text-sm ${disabled ? 'text-gray-400' : 'text-[#6b7280]'}`}>
          {description}
        </p>
      </div>
    </button>
  );
};

export default ActionCard;