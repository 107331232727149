import React, { useEffect, useRef } from 'react';

interface InteractiveMascotProps {
  containerRef: React.RefObject<HTMLDivElement>;
  isExcited?: boolean;
}

const InteractiveMascot: React.FC<InteractiveMascotProps> = ({ containerRef, isExcited }) => {
  const leftEyeRef = useRef<SVGCircleElement>(null);
  const rightEyeRef = useRef<SVGCircleElement>(null);
  const leftEyebrowRef = useRef<SVGPathElement>(null);
  const rightEyebrowRef = useRef<SVGPathElement>(null);
  const mascotRef = useRef<SVGSVGElement>(null);
  const isMobileRef = useRef(window.innerWidth < 768);
  const isHoveredRef = useRef(false);

  useEffect(() => {
    const handleResize = () => {
      isMobileRef.current = window.innerWidth < 768;
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    let animationFrameId: number;
    let lastUpdateTime = 0;
    let currentAngle = 0;
    let targetAngle = 0;
    let transitionProgress = 1;
    const transitionDuration = 300; // Faster transitions

    const handleMouseEnter = () => {
      isHoveredRef.current = true;
    };

    const handleMouseLeave = () => {
      isHoveredRef.current = false;
    };

    if (mascotRef.current) {
      mascotRef.current.addEventListener('mouseenter', handleMouseEnter);
      mascotRef.current.addEventListener('mouseleave', handleMouseLeave);
    }

    const getRandomPosition = () => {
      const lookDirections = [
        { angle: -0.5, eyebrowLift: -2 },
        { angle: 0.5, eyebrowLift: -2 },
        { angle: 0, eyebrowLift: 0 },
        { angle: -0.3, eyebrowLift: -1 },
        { angle: 0.3, eyebrowLift: -1 },
      ];
      return lookDirections[Math.floor(Math.random() * lookDirections.length)];
    };

    const updateEyePosition = (timestamp: number) => {
      if (!leftEyeRef.current || !rightEyeRef.current || !leftEyebrowRef.current || !rightEyebrowRef.current) return;

      // Excited state - look at upload box (to the right in RTL layout)
      if (isExcited) {
        const excitedEyeX = 6; // Look right (towards upload box in RTL)
        const excitedEyeY = -8;
        const excitedEyebrowY = -12; // Moved up to stay above eyes
        
        leftEyeRef.current.style.transform = `translate(${excitedEyeX}px, ${excitedEyeY}px)`;
        rightEyeRef.current.style.transform = `translate(${excitedEyeX}px, ${excitedEyeY}px)`;
        leftEyebrowRef.current.style.transform = `translateY(${excitedEyebrowY}px) rotate(-5deg)`;
        rightEyebrowRef.current.style.transform = `translateY(${excitedEyebrowY}px) rotate(-5deg)`;
        
        animationFrameId = requestAnimationFrame(updateEyePosition);
        return;
      }

      if (isMobileRef.current && !isHoveredRef.current) {
        if (timestamp - lastUpdateTime > 2000) {
          const newPosition = getRandomPosition();
          targetAngle = newPosition.angle;
          transitionProgress = 0;
          lastUpdateTime = timestamp;
        }

        if (transitionProgress < 1) {
          transitionProgress = Math.min(1, (timestamp - lastUpdateTime) / transitionDuration);
          const easeProgress = transitionProgress < 0.5
            ? 4 * transitionProgress * transitionProgress * transitionProgress
            : 1 - Math.pow(-2 * transitionProgress + 2, 3) / 2;
          
          currentAngle = currentAngle + (targetAngle - currentAngle) * easeProgress;
        }

        const maxRadius = 8;
        const leftMoveX = Math.cos(currentAngle) * maxRadius;
        const leftMoveY = Math.sin(currentAngle) * maxRadius;
        const rightMoveX = Math.cos(currentAngle) * maxRadius;
        const rightMoveY = Math.sin(currentAngle) * maxRadius;

        leftEyeRef.current.style.transform = `translate(${leftMoveX}px, ${leftMoveY}px)`;
        rightEyeRef.current.style.transform = `translate(${rightMoveX}px, ${rightMoveY}px)`;
        
        const eyebrowLift = Math.sin(currentAngle) * 2;
        const eyebrowY = -eyebrowLift - 4; // Added offset to keep eyebrows above eyes
        leftEyebrowRef.current.style.transform = `translateY(${eyebrowY}px)`;
        rightEyebrowRef.current.style.transform = `translateY(${eyebrowY}px)`;
      } else if (!isHoveredRef.current) {
        const handleMouseMove = (e: MouseEvent) => {
          if (!mascotRef.current || !leftEyeRef.current || !rightEyeRef.current || isHoveredRef.current) return;

          const mascotRect = mascotRef.current.getBoundingClientRect();
          const mascotCenterX = mascotRect.left + mascotRect.width / 2;
          const mascotCenterY = mascotRect.top + mascotRect.height / 2;

          // Calculate angle and distance from mascot center to cursor
          const angle = Math.atan2(e.clientY - mascotCenterY, e.clientX - mascotCenterX);
          const distance = Math.min(
            Math.hypot(e.clientX - mascotCenterX, e.clientY - mascotCenterY) / 200,
            1
          );

          const maxRadius = 10; // Slightly larger radius for more pronounced movement
          const leftMoveX = Math.cos(angle) * maxRadius * distance;
          const leftMoveY = Math.sin(angle) * maxRadius * distance;
          const rightMoveX = Math.cos(angle) * maxRadius * distance;
          const rightMoveY = Math.sin(angle) * maxRadius * distance;

          // Apply faster transitions
          leftEyeRef.current.style.transition = 'transform 0.1s cubic-bezier(0.4, 0, 0.2, 1)';
          rightEyeRef.current.style.transition = 'transform 0.1s cubic-bezier(0.4, 0, 0.2, 1)';
          
          leftEyeRef.current.style.transform = `translate(${leftMoveX}px, ${leftMoveY}px)`;
          rightEyeRef.current.style.transform = `translate(${rightMoveX}px, ${rightMoveY}px)`;

          const eyebrowLift = Math.sin(angle) * 2 * distance;
          const eyebrowY = -eyebrowLift - 4; // Added offset to keep eyebrows above eyes
          leftEyebrowRef.current.style.transform = `translateY(${eyebrowY}px)`;
          rightEyebrowRef.current.style.transform = `translateY(${eyebrowY}px)`;
        };

        window.addEventListener('mousemove', handleMouseMove);
        return () => window.removeEventListener('mousemove', handleMouseMove);
      }

      animationFrameId = requestAnimationFrame(updateEyePosition);
    };

    animationFrameId = requestAnimationFrame(updateEyePosition);
    
    return () => {
      cancelAnimationFrame(animationFrameId);
      if (mascotRef.current) {
        mascotRef.current.removeEventListener('mouseenter', handleMouseEnter);
        mascotRef.current.removeEventListener('mouseleave', handleMouseLeave);
      }
    };
  }, [isExcited]);

  return (
    <svg ref={mascotRef} className="w-full h-full" viewBox="0 0 200 200">
      {/* Outer circle (thin ring) */}
      <circle cx="100" cy="100" r="75" fill="#f9f6fe" />
      
      {/* Main circle */}
      <circle cx="100" cy="100" r="70" fill="#f3effa" />

      {/* Graduation Cap */}
      <g transform="translate(75, 0) scale(0.55) rotate(15)">
        <path
          d="M50,40 L150,40 L100,20 Z"
          fill="#1a1825"
          filter="url(#shadow)"
        />
        <rect
          x="65"
          y="40"
          width="70"
          height="12"
          fill="#1a1825"
          filter="url(#shadow)"
        />
        <path
          d="M135,45 Q140,55 135,65"
          stroke="#7e41cc"
          strokeWidth="3"
          fill="none"
          filter="url(#shadow)"
        />
        <circle
          cx="135"
          cy="65"
          r="4"
          fill="#7e41cc"
          filter="url(#shadow)"
        />
        <circle
          cx="100"
          cy="20"
          r="3"
          fill="#7e41cc"
        />
      </g>

      <defs>
        <filter id="shadow" x="-20%" y="-20%" width="140%" height="140%">
          <feDropShadow dx="0" dy="2" stdDeviation="2" floodOpacity="0.2"/>
        </filter>
      </defs>

      {/* Left eye */}
      <circle
        ref={leftEyeRef}
        cx="75"
        cy="85"
        r="15"
        fill="#7e41cc"
        style={{ transition: 'transform 0.1s cubic-bezier(0.4, 0, 0.2, 1)' }}
      />
      <circle
        cx="75"
        cy="85"
        r="5"
        fill="white"
        style={{ pointerEvents: 'none' }}
      />

      {/* Right eye */}
      <circle
        ref={rightEyeRef}
        cx="125"
        cy="85"
        r="15"
        fill="#7e41cc"
        style={{ transition: 'transform 0.1s cubic-bezier(0.4, 0, 0.2, 1)' }}
      />
      <circle
        cx="125"
        cy="85"
        r="5"
        fill="white"
        style={{ pointerEvents: 'none' }}
      />

      {/* Left eyebrow - Moved above eyes in SVG structure */}
      <path
        ref={leftEyebrowRef}
        d="M60,65 Q75,58 90,65"
        stroke="#7e41cc"
        strokeWidth="4"
        fill="none"
        style={{ transition: 'transform 0.2s cubic-bezier(0.4, 0, 0.2, 1)' }}
      />

      {/* Right eyebrow - Moved above eyes in SVG structure */}
      <path
        ref={rightEyebrowRef}
        d="M110,65 Q125,58 140,65"
        stroke="#7e41cc"
        strokeWidth="4"
        fill="none"
        style={{ transition: 'transform 0.2s cubic-bezier(0.4, 0, 0.2, 1)' }}
      />

      {/* Smile */}
      <path
        d={isExcited 
          ? "M70,130 Q100,150 130,130" // Bigger smile when excited
          : "M70,130 Q100,160 130,130"
        }
        stroke="#7e41cc"
        strokeWidth="6"
        fill="none"
        style={{ transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)' }}
      />
    </svg>
  );
};

export default InteractiveMascot;