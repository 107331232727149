import React from 'react';
import { Sparkles } from 'lucide-react';
import { hebrewText } from '../lib/hebrew';

interface TokenCounterProps {
  tokensLeft: number;
  timeToReset?: string;
  onUpgradeClick: () => void;
}

const TokenCounter: React.FC<TokenCounterProps> = ({ tokensLeft, timeToReset, onUpgradeClick }) => {
  const isOutOfTokens = tokensLeft === 0;

  return (
    <div
      className={`p-4 rounded-lg flex flex-col sm:flex-row sm:items-center gap-3 sm:gap-0 sm:justify-between transition-all ${
        isOutOfTokens ? 'bg-red-100 border border-red-200' : 'bg-[#7e41cc]/10 border border-[#7e41cc]/20'
      }`}
      dir="rtl"
    >
      <div className="flex items-center gap-3">
        <Sparkles className={`w-5 h-5 ${isOutOfTokens ? 'text-red-500' : 'text-[#7e41cc]'}`} />
        <div className="flex flex-col">
          <span className={`font-medium text-sm sm:text-base ${isOutOfTokens ? 'text-red-600' : 'text-[#7e41cc]'}`}>
            {isOutOfTokens
              ? hebrewText.tokens.noTokens
              : hebrewText.tokens.remaining.replace('{{count}}', tokensLeft.toString())}
          </span>
          {timeToReset && (
            <span className="text-sm text-gray-500">
              מתחדש בעוד {timeToReset}
            </span>
          )}
        </div>
      </div>
      <button
        onClick={onUpgradeClick}
        className={`w-full sm:w-auto px-4 py-2 rounded-lg text-sm font-medium transition-all ${
          isOutOfTokens
            ? 'bg-red-500 text-white hover:bg-red-600'
            : 'bg-[#7e41cc] text-white hover:bg-[#6935a9]'
        }`}
      >
        {hebrewText.tokens.upgrade}
      </button>
    </div>
  );
};

export default TokenCounter;