export const hebrewText = {
  home: {
    title: "מורה פרטי מבוסס בינה מלאכותית",
    subtitle: "צור פודקאסט, מבחן או סיכום מכל קובץ PDF",
    uploadTitle: "העלה את חומר הלימוד שלך",
    uploadSubtitle: "גרור ושחרר את קובץ ה-PDF שלך כאן, או לחץ לעיון",
    browseFiles: "עיון בקבצים",
    features: {
      audio: "למידה קולית",
      testing: "בחינה חכמה",
      summary: "סיכום מהיר"
    }
  },
  howItWorks: {
    title: "איך זה עובד",
    subtitle: "קל כמו אחת, שתיים, שלוש",
    getStarted: "התחל עכשיו",
    steps: [
      {
        title: "העלה את חומר הלימוד שלך",
        description: "פשוט גרור ושחרר את קבצי ה-PDF או ספרי הלימוד שלך"
      },
      {
        title: "בחר את סגנון הלמידה שלך",
        description: "בחר איך אתה רוצה ללמוד: אודיו, מבחן תרגול, או סיכום מהיר"
      },
      {
        title: "למד בקלות",
        description: "תן לבינה המלאכותית שלנו להפוך את החומרים שלך לתוכן לימודי מעניין"
      }
    ]
  },
  features: {
    title: "הצבע על התכונה הבאה!",
    comments: "תגובות",
    addComment: "הוסף תגובה...",
    send: "שלח",
    noComments: "אין עדיין תגובות. היה הראשון!",
    flashcards: {
      title: "כרטיסיות לימוד בבינה מלאכותית",
      description: "יצירה אוטומטית של כרטיסיות לימוד מתוכן ה-PDF שלך. מושלם לחזרה מהירה ושינון."
    },
    multiPdf: {
      title: "העלאת מספר קבצי PDF",
      description: "העלה ועבד מספר קבצי PDF בבת אחת. מעולה לשילוב חומרי לימוד או השוואת מקורות שונים."
    }
  },
  students: {
    title: "הסטודנטים שאיתם אנחנו עובדים",
    testimonials: [
      "StudyMate AI עזר לי להצליח במבחנים הסופיים! 🎉",
      "הסיכומים הקוליים מושלמים לנסיעה שלי 🎧",
      "כלי הלימוד הטוב ביותר שהשתמשתי בו אי פעם 📚",
      "אוהב איך שזה יוצר מבחני תרגול 💯",
      "הופך נושאים מורכבים להרבה יותר קלים להבנה ⭐️",
      "חוסך המון זמן בהכנה למבחנים 🚀"
    ]
  },
  processing: {
    podcast: [
      "מנתח דפוסי אודיו...",
      "ממיר טקסט לדיבור...",
      "מייעל את איכות השמע...",
      "מסיים את הפודקאסט שלך..."
    ],
    test: [
      "מנתח מושגי מפתח...",
      "מייצר שאלות...",
      "מוסיף אפשרויות תשובה...",
      "מסיים את המבחן שלך..."
    ],
    summary: [
      "קורא את תוכן המסמך...",
      "מזהה נקודות עיקריות...",
      "מתמצת מידע...",
      "מכין את הסיכום שלך..."
    ]
  },
  pricing: {
    title: "בחר את התוכנית שלך",
    plans: {
      free: {
        name: "חינם",
        price: "₪0",
        features: [
          "3 יצירות ליום",
          "תמיכה בסיסית",
          "מהירות עיבוד רגילה"
        ],
        buttonText: "התוכנית הנוכחית"
      },
      pro: {
        name: "מקצועי",
        price: "₪35",
        features: [
          "50 יצירות ליום",
          "תמיכת לקוחות בעדיפות",
          "גישה לקהילה",
          "עיבוד מהיר יותר"
        ],
        buttonText: "בחר מקצועי"
      },
      champion: {
        name: "אלוף",
        price: "₪89",
        features: [
          "יצירות ללא הגבלה",
          "תמיכה פרימיום",
          "גישה לתכונות בטא",
          "העיבוד המהיר ביותר",
          "מיתוג מותאם אישית"
        ],
        buttonText: "בחר אלוף"
      }
    }
  },
  errors: {
    pdfOnly: "אנא העלה קובץ PDF",
    apiKey: "אנא הגדר את מפתח ה-API שלך בהגדרות הסביבה",
    generation: "נכשל ביצירת התוכן. אנא בדוק את מפתח ה-API שלך ונסה שוב."
  },
  tokens: {
    remaining: "{{count}} אסימוני יצירה נותרו להיום",
    noTokens: "לא נותרו אסימונים להיום",
    upgrade: "שדרג לאסימונים ללא הגבלה"
  }
};