import React, { useState } from 'react';
import { ThumbsUp, MessageSquare, X, ArrowUp } from 'lucide-react';
import { hebrewText } from '../lib/hebrew';

interface Feature {
  id: string;
  title: string;
  description: string;
  votes: number;
  hasVoted: boolean;
  comments: Comment[];
}

interface Comment {
  id: string;
  text: string;
  timestamp: string;
}

const FeatureVoting: React.FC = () => {
  const [features, setFeatures] = useState<Feature[]>([
    {
      id: 'flashcards',
      title: hebrewText.features.flashcards.title,
      description: hebrewText.features.flashcards.description,
      votes: 0,
      hasVoted: false,
      comments: [],
    },
    {
      id: 'multi-pdf',
      title: hebrewText.features.multiPdf.title,
      description: hebrewText.features.multiPdf.description,
      votes: 0,
      hasVoted: false,
      comments: [],
    },
  ]);

  const [activeComments, setActiveComments] = useState<string | null>(null);
  const [newComment, setNewComment] = useState('');

  const handleVote = (featureId: string) => {
    setFeatures(features.map(feature => 
      feature.id === featureId && !feature.hasVoted
        ? { ...feature, votes: feature.votes + 1, hasVoted: true }
        : feature
    ));
  };

  const handleAddComment = (featureId: string) => {
    if (!newComment.trim()) return;

    const comment: Comment = {
      id: Date.now().toString(),
      text: newComment,
      timestamp: new Date().toLocaleString('he-IL'),
    };

    setFeatures(features.map(feature =>
      feature.id === featureId
        ? { ...feature, comments: [...feature.comments, comment] }
        : feature
    ));

    setNewComment('');
  };

  return (
    <div className="mt-16" dir="rtl">
      <div className="bg-white rounded-2xl border border-[#eaecef] overflow-hidden">
        <div className="p-6 border-b border-[#eaecef]">
          <h2 className="text-2xl font-miriam font-bold text-center">{hebrewText.features.title}</h2>
        </div>
        
        <div className="divide-y divide-[#eaecef]">
          {features.map(feature => (
            <div key={feature.id} className="transition-colors hover:bg-[#f9fafb]">
              <div className="p-6">
                <div className="flex flex-row-reverse gap-6">
                  <div className="flex-1">
                    <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
                    <p className="text-[#6b7280] mb-4">{feature.description}</p>
                    
                    <button
                      onClick={() => setActiveComments(activeComments === feature.id ? null : feature.id)}
                      className="inline-flex items-center gap-2 text-[#6b7280] hover:text-[#7e41cc] transition-colors"
                    >
                      <MessageSquare className="w-4 h-4" />
                      <span>{feature.comments.length} {hebrewText.features.comments}</span>
                    </button>
                  </div>

                  <div className="flex-shrink-0">
                    <button
                      onClick={() => handleVote(feature.id)}
                      disabled={feature.hasVoted}
                      className={`flex flex-col items-center gap-2 px-6 py-3 rounded-xl transition-all transform hover:scale-105 active:scale-95 ${
                        feature.hasVoted
                          ? 'bg-[#7e41cc]/10 text-[#7e41cc] cursor-default'
                          : 'bg-[#7e41cc]/5 hover:bg-[#7e41cc]/10 text-[#7e41cc] hover:shadow-md'
                      }`}
                    >
                      <ArrowUp className="w-5 h-5" />
                      <span className="font-medium">{feature.votes}</span>
                    </button>
                  </div>
                </div>

                {activeComments === feature.id && (
                  <div className="mt-6">
                    <div className="mb-4">
                      <div className="flex gap-2">
                        <button
                          onClick={() => handleAddComment(feature.id)}
                          className="px-4 py-2 bg-[#7e41cc] text-white rounded-xl hover:bg-[#6935a9] transition-colors"
                        >
                          {hebrewText.features.send}
                        </button>
                        <input
                          type="text"
                          value={newComment}
                          onChange={(e) => setNewComment(e.target.value)}
                          placeholder={hebrewText.features.addComment}
                          className="flex-1 px-4 py-2 rounded-xl border border-[#eaecef] focus:outline-none focus:ring-2 focus:ring-[#7e41cc]/20"
                        />
                      </div>
                    </div>

                    <div className="space-y-4 max-h-60 overflow-y-auto">
                      {feature.comments.map(comment => (
                        <div key={comment.id} className="bg-[#f9fafb] p-4 rounded-xl border border-[#eaecef]">
                          <p className="text-[#1a1825] mb-2">{comment.text}</p>
                          <p className="text-sm text-[#6b7280]">{comment.timestamp}</p>
                        </div>
                      ))}
                      {feature.comments.length === 0 && (
                        <p className="text-center text-[#6b7280] py-4">{hebrewText.features.noComments}</p>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FeatureVoting;