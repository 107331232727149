import React from 'react';

interface ProgressBarProps {
  progress: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ progress }) => {
  return (
    <div className="w-full bg-[#f3effa] rounded-full h-2 overflow-hidden">
      <div
        className="h-full bg-gradient-to-r from-[#7e41cc] to-[#a87ee6] transition-all duration-300 ease-out"
        style={{ width: `${progress}%` }}
      />
    </div>
  );
};

export default ProgressBar;