import React, { useEffect, useState } from 'react';
import { Mail } from 'lucide-react';
import { auth } from '../lib/firebase';
import { resendVerificationEmail } from '../lib/auth';

const VerificationBanner: React.FC = () => {
  const [show, setShow] = useState(false);
  const [isResending, setIsResending] = useState(false);

  useEffect(() => {
    const checkVerification = async () => {
      const user = auth.currentUser;
      if (user) {
        // Force reload user data to get the latest emailVerified status
        await user.reload();
        setShow(!user.emailVerified);
      } else {
        setShow(false);
      }
    };

    // Check immediately
    checkVerification();

    // Set up a listener for auth state changes
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        checkVerification();
      } else {
        setShow(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleResendEmail = async () => {
    if (isResending) return;
    
    try {
      setIsResending(true);
      await resendVerificationEmail();
      alert('אימייל אימות נשלח בהצלחה');
    } catch (error) {
      console.error('Error resending verification email:', error);
      alert('אירעה שגיאה בשליחת אימייל האימות');
    } finally {
      setIsResending(false);
    }
  };

  if (!show) return null;

  return (
    <div className="bg-yellow-50 border-b border-yellow-100" dir="rtl">
      <div className="max-w-5xl mx-auto px-4 sm:px-6 py-3">
        <div className="flex items-center justify-between gap-4">
          <div className="flex items-center gap-2">
            <Mail className="w-5 h-5 text-yellow-600" />
            <p className="text-sm text-yellow-700">
              אנא אמת את כתובת האימייל שלך כדי לגשת לכל התכונות.
            </p>
          </div>
          <button
            onClick={handleResendEmail}
            disabled={isResending}
            className={`text-sm font-medium text-yellow-600 hover:text-yellow-700 transition-colors ${
              isResending ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            {isResending ? 'שולח...' : 'שלח מחדש אימייל אימות'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default VerificationBanner;