import React, { useState } from 'react';
import { CheckCircle2, XCircle, X } from 'lucide-react';

export interface Question {
  question: string;
  options: string[];
  correctAnswer: number;
}

interface TestQuestionsProps {
  questions: Question[];
  onClose: () => void;
}

const TestQuestions: React.FC<TestQuestionsProps> = ({ questions, onClose }) => {
  const [selectedAnswers, setSelectedAnswers] = useState<number[]>(new Array(questions.length).fill(-1));
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleAnswerSelect = (questionIndex: number, answerIndex: number) => {
    if (isSubmitted) return;
    const newAnswers = [...selectedAnswers];
    newAnswers[questionIndex] = answerIndex;
    setSelectedAnswers(newAnswers);
  };

  const handleSubmit = () => {
    if (selectedAnswers.includes(-1)) {
      alert('נא לענות על כל השאלות לפני ההגשה.');
      return;
    }
    setIsSubmitted(true);
  };

  const calculateScore = () => {
    const correctAnswers = selectedAnswers.filter(
      (answer, index) => answer === questions[index].correctAnswer
    ).length;
    return `${correctAnswers}/${questions.length}`;
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white w-full sm:max-w-3xl sm:max-h-[80vh] h-[90vh] sm:h-auto flex flex-col rounded-2xl safe-bottom sm:safe-bottom-0" dir="rtl">
        <div className="p-4 sm:p-6 border-b border-[#eaecef] flex justify-between items-center sticky top-0 bg-white z-10 safe-top sm:safe-top-0 rounded-t-2xl">
          <h2 className="text-xl font-semibold text-[#1a1825]">מבחן ידע</h2>
          <div className="flex items-center gap-4">
            {isSubmitted && (
              <div className="text-lg font-medium">
                ציון: <span className="text-[#7e41cc]">{calculateScore()}</span>
              </div>
            )}
            <button 
              onClick={onClose}
              className="p-2 text-[#6b7280] hover:text-[#1a1825] focus:outline-none focus:ring-2 focus:ring-[#7e41cc]/50 rounded-lg transition-colors"
            >
              <X className="h-6 w-6" />
            </button>
          </div>
        </div>

        <div className="overflow-y-auto p-4 sm:p-6 space-y-6 sm:space-y-8 flex-1 smooth-scroll">
          {questions.map((question, qIndex) => (
            <div key={qIndex} className="space-y-4">
              <div className="flex items-start gap-3">
                <span className="font-medium text-[#1a1825] min-w-[24px]">{qIndex + 1}.</span>
                <div className="flex-1">
                  <p className="font-medium text-[#1a1825] mb-4">{question.question}</p>
                  <div className="space-y-3">
                    {question.options.map((option, oIndex) => {
                      const isSelected = selectedAnswers[qIndex] === oIndex;
                      const isCorrect = question.correctAnswer === oIndex;
                      const showCorrect = isSubmitted && isCorrect;
                      const showIncorrect = isSubmitted && isSelected && !isCorrect;

                      return (
                        <label
                          key={oIndex}
                          className={`flex items-center gap-3 p-4 rounded-lg border transition-all ${
                            isSubmitted ? 'cursor-default' : 'hover:border-[#7e41cc] active:bg-[#7e41cc]/5'
                          } ${showCorrect ? 'border-green-500 bg-green-50' : ''} 
                          ${showIncorrect ? 'border-red-500 bg-red-50' : ''} 
                          ${isSelected && !isSubmitted ? 'border-[#7e41cc]' : 'border-[#eaecef]'}`}
                        >
                          <input
                            type="radio"
                            name={`question-${qIndex}`}
                            checked={isSelected}
                            onChange={() => handleAnswerSelect(qIndex, oIndex)}
                            className="w-5 h-5 text-[#7e41cc] focus:ring-[#7e41cc]"
                            disabled={isSubmitted}
                          />
                          <span className="flex-1 text-[#1a1825] text-base sm:text-lg">{option}</span>
                          {showCorrect && <CheckCircle2 className="w-5 h-5 text-green-500 flex-shrink-0" />}
                          {showIncorrect && <XCircle className="w-5 h-5 text-red-500 flex-shrink-0" />}
                        </label>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="p-4 sm:p-6 border-t border-[#eaecef] flex justify-start gap-4 sticky bottom-0 bg-white safe-bottom sm:safe-bottom-0 rounded-b-2xl">
          {!isSubmitted && (
            <button
              onClick={handleSubmit}
              className="px-6 py-2 bg-gradient-to-r from-[#7e41cc] to-[#a87ee6] text-white rounded-lg hover:from-[#6935a9] hover:to-[#9165d7] transition-colors focus:outline-none focus:ring-2 focus:ring-[#7e41cc]/50 focus:ring-offset-2"
            >
              הגש תשובות
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default TestQuestions;