import React from 'react';
import { X } from 'lucide-react';

interface SummaryModalProps {
  isOpen: boolean;
  onClose: () => void;
  summary: string;
}

const SummaryModal: React.FC<SummaryModalProps> = ({ isOpen, onClose, summary }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-end sm:items-center justify-center z-50">
      <div className="bg-white w-full sm:max-w-2xl sm:max-h-[80vh] h-[90vh] sm:h-auto flex flex-col rounded-t-[28px] sm:rounded-[28px] safe-bottom sm:safe-bottom-0" dir="rtl">
        <div className="flex justify-between items-center p-4 sm:p-6 border-b border-[#eaecef] sticky top-0 bg-white z-10 rounded-t-[28px] safe-top sm:safe-top-0">
          <h2 className="text-xl font-semibold text-[#1a1825]">סיכום המסמך</h2>
          <button
            onClick={onClose}
            className="p-2 -m-2 text-[#6b7280] hover:text-[#1a1825] focus:outline-none focus:ring-2 focus:ring-[#7e41cc]/50 rounded-full"
          >
            <X className="h-6 w-6" />
          </button>
        </div>
        <div className="p-4 sm:p-6 overflow-y-auto flex-1 smooth-scroll">
          <div className="prose prose-sm sm:prose max-w-none">
            {summary.split('\n').map((paragraph, index) => {
              // Check if the paragraph is a header (starts with #)
              if (paragraph.startsWith('#')) {
                const level = paragraph.match(/^#+/)[0].length;
                const title = paragraph.replace(/^#+\s*/, '');
                const HeaderTag = `h${Math.min(level + 1, 6)}` as keyof JSX.IntrinsicElements;
                return (
                  <HeaderTag key={index} className="font-bold text-[#1a1825] mt-6 mb-3">
                    {title}
                  </HeaderTag>
                );
              }
              
              // Check if the paragraph is a bullet point
              if (paragraph.trim().startsWith('•')) {
                return (
                  <li key={index} className="text-[#1a1825] text-base sm:text-lg leading-relaxed mb-2">
                    {paragraph.trim().substring(1)}
                  </li>
                );
              }
              
              // Regular paragraph
              if (paragraph.trim()) {
                return (
                  <p key={index} className="text-[#1a1825] text-base sm:text-lg leading-relaxed mb-4">
                    {paragraph}
                  </p>
                );
              }
              
              return null;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SummaryModal;