import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import MainContent from './components/MainContent';
import ProfilePage from './components/ProfilePage';
import VerificationBanner from './components/VerificationBanner';
import { useAuth } from './hooks/useAuth';
import { useUserStats } from './hooks/useUserStats';

const App: React.FC = () => {
  const { user } = useAuth();
  const { tokensLeft } = useUserStats(user?.uid);

  const handleTokenUse = () => {
    // Token handling will be implemented later
  };

  return (
    <Router>
      <div className="min-h-screen bg-white">
        <Header />
        {user && !user.emailVerified && <VerificationBanner />}
        <Routes>
          <Route 
            path="/" 
            element={
              <MainContent 
                tokensLeft={tokensLeft} 
                onTokenUse={handleTokenUse} 
              />
            } 
          />
          <Route 
            path="/profile" 
            element={
              <ProfilePage 
                tokensLeft={tokensLeft} 
              />
            } 
          />
        </Routes>
      </div>
    </Router>
  );
};

export default App;