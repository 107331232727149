import React from 'react';
import { FileText, Sparkles, Headphones } from 'lucide-react';
import { hebrewText } from '../lib/hebrew';

interface HowItWorksProps {
  onGetStarted: () => void;
}

const HowItWorks: React.FC<HowItWorksProps> = ({ onGetStarted }) => {
  const steps = [
    {
      number: "1",
      icon: FileText,
      title: hebrewText.howItWorks.steps[0].title,
      description: hebrewText.howItWorks.steps[0].description,
      gradient: "from-[#7e41cc]/5 to-[#a87ee6]/10"
    },
    {
      number: "2",
      icon: Sparkles,
      title: hebrewText.howItWorks.steps[1].title,
      description: hebrewText.howItWorks.steps[1].description,
      gradient: "from-[#ff8f50]/5 to-[#ffbd91]/10"
    },
    {
      number: "3",
      icon: Headphones,
      title: hebrewText.howItWorks.steps[2].title,
      description: hebrewText.howItWorks.steps[2].description,
      gradient: "from-[#ff4d8f]/5 to-[#ff9ec6]/10"
    }
  ];

  const handleGetStarted = () => {
    // Scroll to top smoothly
    window.scrollTo({ top: 0, behavior: 'smooth' });

    // Add flash class to upload box after scrolling
    setTimeout(() => {
      const uploadBox = document.querySelector('.upload-box');
      if (uploadBox) {
        uploadBox.classList.add('flash-animation');
        // Remove the class after animation completes
        setTimeout(() => {
          uploadBox.classList.remove('flash-animation');
        }, 1000);
      }
    }, 500); // Wait for scroll to complete

    onGetStarted();
  };

  return (
    <div className="w-full bg-white relative" dir="rtl">
      <style>
        {`
          @keyframes flash {
            0% { transform: scale(1); box-shadow: 0 0 0 0 rgba(126, 65, 204, 0.4); }
            50% { transform: scale(1.02); box-shadow: 0 0 0 20px rgba(126, 65, 204, 0); }
            100% { transform: scale(1); box-shadow: 0 0 0 0 rgba(126, 65, 204, 0); }
          }

          .flash-animation {
            animation: flash 1s ease-out;
          }
        `}
      </style>
      <div className="max-w-5xl mx-auto px-4 sm:px-6">
        <div className="w-full h-px bg-[#eaecef] mb-16" />
        
        <div className="pb-16">
          <div className="text-center mb-16">
            <p className="text-sm font-medium text-[#7e41cc] mb-3">{hebrewText.howItWorks.subtitle}</p>
            <h3 className="text-4xl md:text-5xl font-serif font-semibold text-[#1a1825] leading-tight">
              {hebrewText.howItWorks.title}
            </h3>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {steps.map((step, index) => {
              const Icon = step.icon;
              const iconColors = [
                'text-[#7e41cc]',
                'text-[#ff8f50]',
                'text-[#ff4d8f]'
              ];
              const borderColors = [
                'group-hover:border-[#7e41cc]',
                'group-hover:border-[#ff8f50]',
                'group-hover:border-[#ff4d8f]'
              ];
              
              return (
                <div 
                  key={index} 
                  className={`relative flex flex-col items-center text-center group p-8 rounded-2xl bg-gradient-to-bl ${step.gradient} border border-[#eaecef] ${borderColors[index]} transition-all duration-300 hover:scale-[1.02]`}
                >
                  <div className="mb-6 relative">
                    <div className={`absolute -top-2 -left-2 w-8 h-8 ${iconColors[index]} bg-white rounded-full flex items-center justify-center font-semibold border border-current`}>
                      {step.number}
                    </div>
                    <div className="relative">
                      <div className={`absolute inset-0 ${iconColors[index]} rounded-2xl opacity-10 group-hover:opacity-20 transition-opacity blur-xl`} />
                      <div className="relative w-20 h-20 flex items-center justify-center bg-white rounded-2xl border border-[#eaecef] shadow-sm group-hover:scale-110 transition-transform">
                        <Icon className={`w-10 h-10 ${iconColors[index]}`} />
                      </div>
                    </div>
                  </div>
                  <h4 className="text-2xl font-semibold text-[#1a1825] mb-4 min-h-[64px] flex items-center">
                    {step.title}
                  </h4>
                  <p className="text-[#6b7280] leading-relaxed text-lg min-h-[56px]">
                    {step.description}
                  </p>
                </div>
              );
            })}
          </div>

          <div className="mt-16 text-center">
            <button
              onClick={handleGetStarted}
              className="inline-flex items-center justify-center px-8 py-4 text-lg font-medium text-white bg-gradient-to-l from-[#7e41cc] to-[#a87ee6] rounded-xl hover:from-[#6935a9] hover:to-[#9165d7] transform hover:scale-105 active:scale-95 transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-[#7e41cc]/50 focus:ring-offset-2"
            >
              {hebrewText.howItWorks.getStarted}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;