import React, { useState } from 'react';
import { CreditCard, Sparkles, Clock, BookOpen } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { useUserStats } from '../hooks/useUserStats';
import PricingModal from './PricingModal';
import ComingSoonPage from './ComingSoonPage';

const ProfilePage: React.FC = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [showPricing, setShowPricing] = useState(false);
  const [showComingSoon, setShowComingSoon] = useState(false);
  const { tokenCount, maxTokens, planType, timeToReset, totalGenerations, loading } = useUserStats(user?.uid);

  if (!user) {
    navigate('/');
    return null;
  }

  if (loading) {
    return (
      <div className="min-h-screen bg-white flex items-center justify-center">
        <div className="animate-pulse">טוען...</div>
      </div>
    );
  }

  const getPlanName = (plan: string) => {
    switch (plan) {
      case 'free':
        return 'חינם';
      case 'mid':
        return 'מקצועי';
      case 'top':
        return 'אלוף';
      default:
        return 'חינם';
    }
  };

  const handleSelectPlan = () => {
    setShowPricing(false);
    setShowComingSoon(true);
  };

  if (showComingSoon) {
    return <ComingSoonPage onBack={() => setShowComingSoon(false)} />;
  }

  return (
    <div className="min-h-screen bg-white" dir="rtl">
      <div className="max-w-4xl mx-auto px-4 py-8">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="bg-[#f9fafb] rounded-xl p-6 border border-[#eaecef]">
            <div className="flex items-center gap-3 mb-4">
              <div className="w-10 h-10 rounded-lg bg-[#7e41cc]/10 flex items-center justify-center">
                <BookOpen className="w-5 h-5 text-[#7e41cc]" />
              </div>
              <h3 className="font-semibold text-[#1a1825]">חומרי לימוד שנוצרו</h3>
            </div>
            <p className="text-3xl font-bold text-[#1a1825]">{totalGenerations || 0}</p>
          </div>

          <div className="bg-[#f9fafb] rounded-xl p-6 border border-[#eaecef]">
            <div className="flex items-center gap-3 mb-4">
              <div className="w-10 h-10 rounded-lg bg-[#7e41cc]/10 flex items-center justify-center">
                <Sparkles className="w-5 h-5 text-[#7e41cc]" />
              </div>
              <h3 className="font-semibold text-[#1a1825]">אסימונים נותרו</h3>
            </div>
            <p className="text-3xl font-bold text-[#1a1825] mb-2">{tokenCount} / {maxTokens}</p>
            <div className="flex items-center gap-2 text-sm text-[#6b7280]">
              <Clock className="w-4 h-4" />
              <span>מתחדש בעוד: {timeToReset}</span>
            </div>
          </div>

          <div className="bg-[#f9fafb] rounded-xl p-6 border border-[#eaecef]">
            <div className="flex items-center gap-3 mb-4">
              <div className="w-10 h-10 rounded-lg bg-[#7e41cc]/10 flex items-center justify-center">
                <CreditCard className="w-5 h-5 text-[#7e41cc]" />
              </div>
              <h3 className="font-semibold text-[#1a1825]">תוכנית נוכחית</h3>
            </div>
            <p className="text-3xl font-bold text-[#1a1825] mb-4">{getPlanName(planType)}</p>
            <button
              onClick={() => setShowPricing(true)}
              className="w-full py-2 px-4 bg-[#7e41cc] text-white rounded-lg hover:bg-[#6935a9] transition-colors"
            >
              שדרג לפרימיום
            </button>
          </div>
        </div>
      </div>

      {showPricing && (
        <PricingModal
          isOpen={showPricing}
          onClose={() => setShowPricing(false)}
          onSelectPlan={handleSelectPlan}
        />
      )}
    </div>
  );
};

export default ProfilePage;